import React from "react"

const UmweltLogo = () => (
  <svg className="fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219 43.1">
    <path d="M32.3,25.6a1.8,1.8,0,0,0-1.2.5c-.2.3-.2.5-.2,1.3v5.3H29.6l-.3-2.2a7.4,7.4,0,0,1-6.8,3.9,7.9,7.9,0,0,1-5.2-2.2c-2.4-2.1-3.1-5-3.1-8.3V12.1c0-1.8-.5-2.4-1.7-2.4H10.8v-1h6.6V23.8c0,3.8.4,5.8,1.4,7.5A4.4,4.4,0,0,0,25,32.6a4.9,4.9,0,0,0,1.8-2,11.9,11.9,0,0,0,.6-3.7c0-1-.4-1.4-1.6-1.4h-.6v-1h7.1Zm-8.8-6.9a8.8,8.8,0,0,1,4,1.7,6.2,6.2,0,0,1,1.7,2.2H25.4a4,4,0,0,0-1.8-2.4,8.7,8.7,0,0,0-4.2-1h-.2v-1a6.4,6.4,0,0,0,4-1,3.9,3.9,0,0,0,1.6-3.4A3.6,3.6,0,0,0,22.1,10a13.6,13.6,0,0,0-2.9-.3v-1h1.4c4.6,0,7.9,2.1,7.9,5a4.2,4.2,0,0,1-2.1,3.4l-2.9,1.5M43.1,0H0V43.1H43.1Z"></path>
    <polygon points="90.1 30.8 93.5 11.1 98.5 11.1 102.5 21.6 106.7 11.1 111.8 11.1 114.7 30.8 109.6 30.8 108.2 19.4 103.4 30.8 101.4 30.8 96.9 19.4 95.2 30.8 90.1 30.8"></polygon>
    <polygon points="127.3 11.1 130.7 23.8 134.9 11.1 138.9 11.1 142.8 23.8 146.5 11.1 151.8 11.1 145.6 30.8 140.4 30.8 136.8 19.4 133 30.8 127.9 30.8 122 11.1 127.3 11.1"></polygon>
    <polygon points="171.7 15.4 165.7 15.4 165.7 18.7 171.7 18.7 171.7 23 165.7 23 165.7 26.4 171.7 26.4 171.7 30.8 160.6 30.8 160.6 11.1 171.7 11.1 171.7 15.4"></polygon>
    <polygon points="190.1 11.1 190.1 26.4 196.2 26.4 196.2 30.8 185 30.8 185 11.1 190.1 11.1"></polygon>
    <polygon points="205.5 11.1 205.5 15.4 209.7 15.4 209.7 30.8 214.8 30.8 214.8 15.4 219 15.4 219 11.1 205.5 11.1">
    </polygon>
    <path d="M69.7,11.1V21.7a10.1,10.1,0,0,0,.2,2.5,2.9,2.9,0,0,0,.5,1.3,3.7,3.7,0,0,0,2.7,1.3A3.8,3.8,0,0,0,76,25.5a3.8,3.8,0,0,0,.6-1.3,20.9,20.9,0,0,0,.1-2.5V11.1h5.1V22.4a13.2,13.2,0,0,1-.6,3.9A6.4,6.4,0,0,1,79.3,29a8.8,8.8,0,0,1-6.2,2.2A8.5,8.5,0,0,1,66.6,29a5.4,5.4,0,0,1-1.5-2.5,14.1,14.1,0,0,1-.5-4V11.1Z"></path>
  </svg>
)

export default UmweltLogo