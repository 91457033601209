import React from "react"
import FadeInLink from "../transitions/FadeInLink"

import ChatBot from './ChatBot'

const GetTalking = () => (
  <section className="GetTalking">
    <div className="outer-padding-x outer-padding-y">
      <h2 className="fadein">Let's get talking!</h2>
      <p className="fadein" style={{maxWidth: 600}}><FadeInLink to="/contact" className="Link" type="default">Feel free to reach out</FadeInLink>. Or you can try talking to my virtual assistant the HenrikoBot™ but be aware I never really know what he is up to.</p>
      <ChatBot />
    </div>
  </section>
)

export default GetTalking