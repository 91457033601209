import React, { useState } from 'react';
import Img from "gatsby-image"

import TestimonialsData from '../../data/testimonials'

const TestimonialSlider = () => {
  const testimonials = TestimonialsData();
  const [activeId, setActiveId] = useState(1);
  return (
    <div className="TestimonialSlider fadein">
      <div className="TestimonialSlider__inner">
        {testimonials.map((testimonial) =>
          <blockquote key={testimonial.id} className={testimonial.id === activeId ? 'TestimonialSlider__testimonial TestimonialSlider__testimonial--active' : 'TestimonialSlider__testimonial'}>
            <header>
              <figure>
                <Img fluid={testimonial.image} />
              </figure>
              <cite>
                <span>{testimonial.name}</span>
                <span>{testimonial.title}</span>
              </cite>
            </header>
            <p className="has-small-font-size">“ {testimonial.testimonial}</p>
          </blockquote>
        )}
      </div>
      <div className="TestimonialSlider__track">
        <nav>
          <ul className="list-unstyled">
            {testimonials.map((testimonial) =>
              <li key={testimonial.id} className={testimonial.id === activeId ? 'active' : ''}>
                <button onClick={() => setActiveId(testimonial.id)}><span></span></button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default TestimonialSlider