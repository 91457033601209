import React from "react"

const ManyoneLogo = () => (
  <svg className="fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1271.2 291.9">
    <path d="M749.7,125c0-51.8,30.3-79.5,76-79.5c46.1,0,76,27.4,76,79.8c0,51.7-30,79.8-76,79.8   C780,205.1,749.7,177,749.7,125z M794.8,125c0,57.7,12,75.1,30.9,75.1s31.2-17,31.2-74.8s-12.3-74.8-31.2-74.8   S794.8,67.2,794.8,125z"/>
    <path d="M1004.8,67.2c-12.9,0-22.1,8.7-28.7,20v94.2c0,3.5,0.3,4.4,3.5,6.9l18.6,13.6v0.6h-83.6V202l18.6-13.6   c2.8-2.2,3.2-3.5,3.2-6.9V71.7c0-4.1-0.3-5-3.2-7.3l-18.6-15.8V48h61.5v35.2c7.6-25.2,24-37.7,48.3-37.7   c29.6,0,46.1,20.8,46.1,64.4c0,17.4-0.3,46.4-0.6,71.6c0,3.8,0.6,4.7,3.5,6.9L1092,202v0.6h-83.6V202l17.6-13.6   c3.2-2.2,3.8-3.8,3.8-6.6c0-3.5,1-49.2,1-63.1C1030.7,84.6,1024.1,67.2,1004.8,67.2z"/>
    <path d="M1239.3,14.1c3.5-3.6,8-5.4,13.3-5.4s9.8,1.8,13.3,5.4s5.3,8.1,5.3,13.5s-1.8,9.9-5.3,13.5s-8,5.4-13.3,5.4    s-9.8-1.8-13.3-5.4s-5.3-8.1-5.3-13.5C1234,22.1,1235.7,17.6,1239.3,14.1z M1241.7,38.9c2.9,3,6.6,4.5,10.9,4.5s8-1.5,10.9-4.5    s4.4-6.8,4.4-11.4s-1.5-8.4-4.4-11.4s-6.6-4.5-10.9-4.5s-8,1.5-10.9,4.5s-4.4,6.8-4.4,11.4C1237.3,32.1,1238.8,35.9,1241.7,38.9z     M1259.6,24c0,1.2-0.3,2.3-0.9,3.2c-0.6,0.9-1.6,1.6-2.8,2l4.4,6.6h-4.5l-3.5-5.6h-2.2v5.6h-3.7V18.3h7c1.9,0,3.4,0.6,4.6,1.7    C1259,21.2,1259.6,22.5,1259.6,24z M1250,26.6h3.3c0.9,0,1.5-0.2,1.9-0.7s0.6-1,0.6-1.7c0-0.6-0.2-1.2-0.6-1.6s-1-0.7-1.9-0.7    h-3.4L1250,26.6L1250,26.6z"/>
    <path d="M1143.6,141.3c-1.3-6.4-2-13.5-2-21.4v-0.7h73.6h18h1.9c1.5-3,1.8-11.4,1.8-11.4l0,0c0-0.3,0-0.6,0-0.9   c0-44.8-31.9-61.5-63.7-61.5c-35.3,0-72.2,21.8-72.2,81.1c0,54.6,34.1,78.6,70.7,78.6c29.1,0,49.9-13.1,60.2-40.3   c-13.8,12.8-25.5,16.6-42.2,17.4C1149.7,184.2,1143.6,141.7,1143.6,141.3z M1141.5,107.2c1.6-38.8,12.3-56.7,30.9-56.7   c17.4,0,25.9,14.7,25.9,50.7c0,1.2-0.2,7.3-0.5,10.2h-56.3V107.2z"/>
    <path d="M533.6,188.4L515.9,202v0.6h83.6V202l-18.6-13.6c-2.9-2.2-3.5-3.2-3.5-6.9c0.3-25.2,0.6-54.3,0.6-71.6   c0-43.5-16.4-64.4-46.1-64.4c-24.3,0-40.7,12.5-48.3,37.7V48h-61.5v0.6l18.6,15.8c2.8,2.2,3.2,3.1,3.2,7.3v109.8   c0,3.5-0.3,4.7-3.2,6.9L422.2,202v0.6h83.6V202l-18.6-13.6c-3.2-2.5-3.5-3.5-3.5-6.9V87.3c6.6-11.4,15.8-20,28.7-20   c19.2,0,25.9,17.4,25.9,51.4c0,13.9-1,59.6-1,63.1C537.4,184.6,536.8,186.2,533.6,188.4z"/>
    <path d="M140,136.9L81.2,1.9L5.6,0v0.6l26.5,24.6c2.9,2.5,3.2,3.1,3.2,8.5V161c0,4.7-0.6,5.7-4.4,9.8L0,201.7v0.6h78.4   v-0.6l-30.6-30.9c-4.1-4.1-4.7-4.7-4.7-9.8V31.9c0-1.9,0.9-1.4,3.2,2.2c2,3.2,6.7,12,7.8,14.4l65.7,152.2c0,0,0.6,1.7,2.1,1.7   s0.9,0,0.9,0l65.7-165.8c-0.6,6.3-0.9,14.5-0.9,20.2v121c0,3.8-0.6,4.7-3.8,6.9l-24.3,17v0.6h99.7v-0.6l-24.3-17   c-3.5-2.2-3.8-3.1-3.8-6.9V26.2c0-3.8,0.3-4.7,3.8-6.9l24.3-18.6V0h-64.7L140,136.9z"/>
    <path d="M398.3,184.3c-1.7-0.6-3.1-1.8-4.1-3.4c-0.2-0.2-0.3-0.5-0.4-0.7s-0.2-0.4-0.3-0.6c-1.2-2.2-1.8-5.2-2-9   c0-0.4,0-0.7-0.1-1.1v-0.1c0-0.1,0-0.2,0-0.3c0-1,0-2.1,0-3.3c0.2-15.7,1.8-48.4,1.8-48.4l0.1-2.5v-5.7c0-25.9-5.7-41.3-14.8-50.8   c-10.1-9.8-24.3-12.9-44.2-12.9c-18.9,0-38.5,3.5-57.7,11l16.7,42.9h0.6L316,54.9c1.9-3.8,2.5-4.1,9.5-4.1c19.9,0,29,16.1,29,39.1   v1.3l-0.6,30.4c-8.8-6.6-21.1-8.7-33.1-8.7c-25.6,0-52,18.4-52,49.6c0,27.4,19.6,42.6,42,42.6c20.2,0,36.6-12.3,42.9-32.2   c0.3,21.8,13.3,32.2,29,32.2c13.6,0,23-7.6,27.1-17c0.7-1.5,1.2-3,1.6-4.6C406.4,185.3,401.8,185.8,398.3,184.3z M353.3,168.6   c-5.7,9.1-14.5,16.4-23.7,16.4c-12.3,0-21.8-10.1-21.8-27.1c0-18,10.7-35.8,30.6-35.8c5,0,10.4,1.4,15.5,3.3L353.3,168.6z"/>
    <path d="M583.4,266.6c-1.1-17.3,10.9-28.3,32.7-27.5c0,0,10.9,28.2,13.2,29.8c1,0.7,8.7,7.3,22.1-23.7l13.9-32.7   L608.5,71.7c-2.2-5.4-3.8-7.6-5.7-10.7l-9-12.3V48h72.1v0.6L652,64.1c-2.2,2.5-2.5,3.8-1,7.6l35.3,87.1l27.6-69l0.7-1.8   c1.9-5,1.9-6-0.6-10.1l-17.5-29.2V48H748v0.6l-18.9,27.8c-3.1,4.7-4.4,7.3-6.9,13.3l-62,154.1c-9.1,22.5-19.8,43.1-43,47.5   C601.3,294.2,584.7,286.2,583.4,266.6z"/>
  </svg>
)

export default ManyoneLogo