import React from "react"

import FadeInLink from "../transitions/FadeInLink"
import icons from '../../images/icons.svg'
const ThumbsupIcon = icons + '#thumbsup'
const ThumbsdownIcon = icons + '#thumbsdown'

const WhatIDo = () => (
  <section className="Whatido">
    <div className="outer-padding-x outer-padding-y">
      <div className="Whatido__wrapper">
        <div className="Whatido__inner">
          <div>
            <h4 className="fadein">
              <span>I might be your guy if you're looking for</span>
            </h4>
            <ul className="list-unstyled">
              <li className="fadein">            
                <svg className="fill-black">
                  <use xlinkHref={ThumbsupIcon}></use>
                </svg> 
                <span>A frontend developer who can build things from scratch</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsupIcon}></use>
                </svg> 
                <span>A developer with a good understanding of design</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsupIcon}></use>
                </svg>
                <span>A creative coder who priorities performance</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsupIcon}></use>
                </svg>
                <span>A UX engineer who can analyze and optimize digital journeys</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsupIcon}></use>
                </svg>  
                <span>A partner and collaborator in the digital space</span>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="fadein">
              <span>I'm not your guy if you're looking for</span>
            </h4>
            <ul className="list-unstyled">
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsdownIcon}></use>
                </svg>
                <span>A cookie cutter</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsdownIcon}></use>
                </svg>
                <span>A developer for backend or DevOps services</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsdownIcon}></use>
                </svg>
                <span>A guy to update your Wix or Squarespace site</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsdownIcon}></use>
                </svg>
                <span>A developer to implement unethical tools or dark patterns</span>
              </li>
              <li className="fadein">
                <svg className="fill-black">
                  <use xlinkHref={ThumbsdownIcon}></use>
                </svg>
                <span>Somebody to fix your printer or wifi (this has happened)</span>
              </li>
            </ul>
          </div>
          <FadeInLink to="/services" className="Link fadein" type="default">More details? See my services</FadeInLink>
        </div>
      </div>
    </div>
  </section>
)

export default WhatIDo