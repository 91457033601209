import React from "react"

import TestimonialSlider from './TestimonialSlider'
import ClientLogos from './ClientLogos'

const Testimonials = () => {
  return (
    <section className="Testimonials">
      <div className="outer-padding-x outer-padding-y bg-lightgray">
        <h2 className="fadein">Don't take my word for it</h2>
        <p className="fadein" style={{ maxWidth: 565 }}>Take theirs! I've been fortunate to work with talented individuals and organizations, some of them you can find below.</p>
        <TestimonialSlider/>
        <ClientLogos/>
      </div>
    </section>
  )
}

export default Testimonials