import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"
import AboutHero from "../components/about/Hero"
import WhatIDo from "../components/about/WhatIDo"
import Testimonials from "../components/about/Testimonials"
import GetTalking from "../components/about/GetTalking"
import Newsletter from "../components/Newsletter"

const AboutPage = () => (
  <InnerLayout noTopPadding>
    <SEO title="About" bodyClass="AboutPage"/>
    <AboutHero />
    <InnerWrapper>
      <WhatIDo />
      <Testimonials/>
      <GetTalking/>
    </InnerWrapper>
    <Newsletter />
  </InnerLayout>
)

export default AboutPage
