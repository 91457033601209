import React from "react"

import JjLogo from '../../images/logos/jacknjones'
import NovoNordiskFondenLogo from '../../images/logos/nnfonden'
import GanniLogo from '../../images/logos/ganni'
import BakobaLogo from '../../images/logos/bakoba'
import UmweltLogo from '../../images/logos/umwelt'
import ManyoneLogo from '../../images/logos/manyone'

const ClientLogos = () => (
  <div className="ClientLogos">
    <div className="bg-white">
      <ManyoneLogo/>
    </div>
    <div className="bg-white">
      <JjLogo/>
    </div>
    <div className="bg-white">
      <NovoNordiskFondenLogo/>
    </div>
    <div className="bg-white">
      <GanniLogo/>
    </div>
    <div className="bg-white">
      <BakobaLogo/>
    </div>
    <div className="bg-white">
      <UmweltLogo/>
    </div>
  </div>
)

export default ClientLogos