import { useStaticQuery, graphql } from "gatsby"

const TestimonialsData = () => {

  const data = useStaticQuery(graphql`
    query {
      testimonialImageTomas: file(relativePath: { eq: "testimonial-image-tomas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialImageLasse: file(relativePath: { eq: "testimonial-image-lasse.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialImageSanne: file(relativePath: { eq: "testimonial-image-sanne.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialImageNiels: file(relativePath: { eq: "testimonial-image-niels.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialImageSande: file(relativePath: { eq: "testimonial-image-sande.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const testimonials = [
    {
      id: 1,
      image: data.testimonialImageSande.childImageSharp.fluid,
      name: 'Kjetil Sande',
      title: 'Digital team lead, Tada agency',
      testimonial: "As an agency we have hired Henrik as a freelance developer on many projects with impressive results. He's a good team player who always deliver on time. When working together on a design he has a great understanding of the user experience enabling solutions reach their full potential, and you can trust him to make good decisions independently as well."
    },
    {
      id: 2,
      image: data.testimonialImageNiels.childImageSharp.fluid,
      name: 'Niels Mortensen',
      title: 'Founder & CEO, BAKOBA',
      testimonial: "We've been working with Henrik for several years through the development and growth of our startup. We're in the retail industry and Henrik has helped us with building a custom website and strong eCommerce platform. Having a partner like Henrik on board is very valuable to our startup."
    },
    {
      id: 3,
      image: data.testimonialImageTomas.childImageSharp.fluid,
      name: 'Tomas Zhang Mathiesen',
      title: 'Senior Associate, PwC',
      testimonial: "I've been lucky to work with Henrik cross different organizations and on several digital projects. He is a great developer with deep technical knowledge while also being very skilled in the realm of UX and design. Working with Henrik is a joy and I'm giving him my warmest recommendations."
    },
    {
      id: 4,
      image: data.testimonialImageSanne.childImageSharp.fluid,
      name: 'Sanne Rindholm',
      title: 'Marketing coordinator, Volvo Trucks Denmark',
      testimonial: "I've worked with Henrik on development of a WordPress website. He's a skilled developer with creative and well thought ideas. Work has always been delivered on time and even exceeding expectations. Cooperating with Henrik is a delight and I can highly recommend him as a digital partner."
    },
    {
      id: 5,
      image: data.testimonialImageLasse.childImageSharp.fluid,
      name: 'Lasse M. Tvedt',
      title: 'Developer & Designer, ANTI',
      testimonial: "I've worked closely with Henrik on several digital projects. He's a creative developer with a very strong determination in creating impressive and well thought solutions. He's never scared to take on a challenge and push the boundaries of modern web development. I can recommend him 10/10 <3."
    },
  ]

  return testimonials;

}

export default TestimonialsData;