import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AboutHero = () => {

  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "about-temp-reals.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="Hero Hero--about bg-lightgray outer-padding-x">
      <div className="Hero__inner">
        <div className="Hero--about__content">
          <p className="color-bluegray fadein">New phone, who dis?</p>
          <h1 className="fadein">About</h1>
          <p className="fadein">Hi! I’m Henrik, freelance developer based in Copenhagen. I’ve created over 100 web solutions for a large range of clients but tend to work in particular with creative agencies and startups. I've previously worked in-house at agencies and at startups as well as founding my own.</p>
          <p className="fadein">I try my best to create simple and well-designed products that not only looks good but actually works in the real world. Solutions should have a purpose, tell a story and add value. Not just add to the noise of the attention economy. I like to explore, play, educate and learn with my clients, not just work for them.</p>
          <p className="fadein">Besides I'm a proud <a href="https://datasapiens.life/" target="_blank" rel="noopener noreferrer">Data Sapien</a> and volunteer at <a href="https://www.danishstartupgroup.com/" target="_blank" rel="noopener noreferrer">Danish Startup Group</a>.</p>
        </div>
        <div className="Hero--about__image fadein">
          <Img fluid={data.aboutImage.childImageSharp.fluid} />
          <Img fluid={data.aboutImage.childImageSharp.fluid} />
          <Img fluid={data.aboutImage.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )

}

export default AboutHero