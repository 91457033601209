import React from "react"

const BakobaLogo = () => (
  <svg className="fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1028 208">
    <path d="M832.8,99.6c7.2-7.4,13.3-17.4,13.3-30.2c0-36.3-28.4-54.5-66.7-54.5h-63.6c-3.3,0-6,2.7-6,6V188
		c0,3.3,2.7,6,6,6h73.8c34,0,61.1-20.7,61.1-53.7C850.7,121.4,843.6,108.8,832.8,99.6z M792.5,29.7c3.4,0,6.2,2.8,6.2,6.2
		c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2C786.4,32.4,789.1,29.7,792.5,29.7z M757.7,60.6c0-2.2,1.8-4,4-4h21.6
		c8.9,0,15.3,5.4,15.3,13.6c0,6.6-4.9,13.6-16.4,13.6h-20.6c-2.2,0-4-1.8-4-4V60.6z M733.8,179.3c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C740,176.6,737.2,179.3,733.8,179.3z M733.8,108.7c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C740,105.9,737.2,108.7,733.8,108.7z M733.8,42c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C740,39.3,737.2,42,733.8,42z M792.5,179.3c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C798.7,176.6,795.9,179.3,792.5,179.3z M784.8,152.3h-23.1c-2.2,0-4-1.8-4-4v-23
		c0-2.2,1.8-4,4-4h22.1c14.1,0,20.5,6.6,20.5,15.6C804.2,144.9,799.3,152.3,784.8,152.3z M809.3,108.7c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C815.5,105.9,812.7,108.7,809.3,108.7z"/>
	<path d="M443.4,99.3l52.4-75c2.8-3.9-0.1-9.4-4.9-9.4h-42.1c-2,0-3.9,1-5,2.7L403,82.2c-0.4,0.7-1.2,1.1-2,1.1h-2.2
		c-1.3,0-2.4-1.1-2.4-2.4V21c0-3.3-2.7-6-6-6h-35.4c-3.3,0-6,2.7-6,6V188c0,3.3,2.7,6,6,6h35.4c3.3,0,6-2.7,6-6v-64.2
		c0-1.3,1.1-2.4,2.4-2.4h1.9c0.8,0,1.6,0.4,2,1.1l43.3,68.7c1.1,1.7,3,2.8,5,2.8h43.1c4.8,0,7.6-5.3,4.9-9.3L443.4,102
		C442.8,101.2,442.8,100.1,443.4,99.3z M459.3,29.7c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2
		C453.1,32.4,455.9,29.7,459.3,29.7z M372.5,29.7c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2
		C366.3,32.4,369.1,29.7,372.5,29.7z M372.5,96.3c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2
		C366.3,99.1,369.1,96.3,372.5,96.3z M372.5,179.3c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2
		C378.7,176.6,375.9,179.3,372.5,179.3z M422.3,108.7c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2
		C428.5,105.9,425.7,108.7,422.3,108.7z M463.2,179.3c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
		C469.4,176.6,466.6,179.3,463.2,179.3z"/>
	<path d="M589.4,12.4c-56,0-91.8,41.4-91.8,93.1c0,49.1,35.8,91,91.8,91c55.7,0,91.8-41.9,91.8-91
		C681.2,53.9,645.1,12.4,589.4,12.4z M589.4,29.7c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2
		C583.2,32.4,586,29.7,589.4,29.7z M521.2,108.7c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2
		C527.4,105.9,524.6,108.7,521.2,108.7z M589.4,179.3c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2
		C595.6,176.6,592.8,179.3,589.4,179.3z M617.3,106.2l21.7,21.7c1.5,1.5,1.5,4.1,0,5.6l-20.6,20.6c-1.5,1.5-4.1,1.5-5.6,0l-21.7-21.7
		c-0.9-0.9-2.4-0.9-3.4,0L566,154.1c-1.5,1.6-4.1,1.6-5.6,0l-20.6-20.6c-1.5-1.5-1.5-4.1,0-5.6l21.7-21.7c0.9-0.9,0.9-2.4,0-3.4
		l-21.7-21.7c-1.5-1.5-1.5-4.1,0-5.6l20.6-20.6c1.5-1.5,4.1-1.5,5.6,0l21.7,21.7c0.9,0.9,2.4,0.9,3.4,0l21.7-21.7
		c1.5-1.5,4.1-1.5,5.6,0L639,75.5c1.5,1.5,1.5,4.1,0,5.6l-21.7,21.7C616.3,103.7,616.3,105.3,617.3,106.2z M663.7,102.5
		c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2C660.9,96.3,663.7,99.1,663.7,102.5z"/>
	<path d="M123.9,99.6c7.2-7.4,13.3-17.4,13.3-30.2c0-36.3-28.4-54.5-66.7-54.5H6.9c-3.3,0-6,2.7-6,6V188
		c0,3.3,2.7,6,6,6h73.8c34,0,61.1-20.7,61.1-53.7C141.8,121.4,134.6,108.8,123.9,99.6z M83.6,29.7c3.4,0,6.2,2.8,6.2,6.2
		c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2C77.4,32.4,80.2,29.7,83.6,29.7z M48.7,60.6c0-2.2,1.8-4,4-4h21.6
		c8.9,0,15.3,5.4,15.3,13.6c0,6.6-4.9,13.6-16.4,13.6H52.7c-2.2,0-4-1.8-4-4V60.6z M24.9,179.3c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C31.1,176.6,28.3,179.3,24.9,179.3z M24.9,108.7c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C31.1,105.9,28.3,108.7,24.9,108.7z M24.9,42c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C31.1,39.3,28.3,42,24.9,42z M83.6,179.3c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C89.8,176.6,87,179.3,83.6,179.3z M75.8,152.3H52.7c-2.2,0-4-1.8-4-4v-23
		c0-2.2,1.8-4,4-4h22.1c14.1,0,20.5,6.6,20.5,15.6C95.3,144.9,90.4,152.3,75.8,152.3z M100,108.7c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C106.2,105.9,103.4,108.7,100,108.7z"/>
	<path d="M951.7,13.1c-36.6,0-73.6,27.1-73.6,69V188c0,3.3,2.7,6,6,6h35.9c3.3,0,6-2.7,6-6v-62.7c0-2.2,1.8-4,4-4
		h43.7c2.2,0,4,1.8,4,4V188c0,3.3,2.7,6,6,6h35.7c3.3,0,6-2.7,6-6V82.1C1025.1,42.7,988.3,13.1,951.7,13.1z M902,179.3
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C908.2,176.6,905.5,179.3,902,179.3z M902,108.7
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C908.2,105.9,905.5,108.7,902,108.7z M951.6,29.7
		c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2C945.4,32.4,948.2,29.7,951.6,29.7z M951.6,108.7
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C957.8,105.9,955,108.7,951.6,108.7z M977.7,79.8
		c0,2.2-1.8,4-4,4H930c-2.2,0-4-1.8-4-4v-0.2c0-15.4,12-23,25.8-23c13.8,0,25.8,9.3,25.8,23V79.8z M1001.1,179.3
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C1007.3,176.6,1004.5,179.3,1001.1,179.3z M1001.1,108.7
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C1007.3,105.9,1004.5,108.7,1001.1,108.7z"/>
	<path d="M242.6,13.1c-36.6,0-73.6,27.1-73.6,69v105.8c0,3.3,2.7,6,6,6h35.9c3.3,0,6-2.7,6-6v-62.5c0-2.2,1.8-4,4-4
		h43.7c2.2,0,4,1.8,4,4v62.5c0,3.3,2.7,6,6,6h35.7c3.3,0,6-2.7,6-6V82.1C316,42.7,279.2,13.1,242.6,13.1z M193,179.3
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C199.1,176.6,196.4,179.3,193,179.3z M193,108.7
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C199.1,105.9,196.4,108.7,193,108.7z M242.5,29.6
		c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2s-6.2-2.8-6.2-6.2C236.3,32.4,239.1,29.6,242.5,29.6z M242.5,108.7
		c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2C248.7,105.9,245.9,108.7,242.5,108.7z M268.6,79.6c0,2.2-1.8,4-4,4
		h-43.7c-2.2,0-4-1.8-4-4v-0.2c0-15.4,12-22.9,25.8-22.9c13.8,0,25.8,9.1,25.8,22.9V79.6z M292,179.3c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C298.2,176.6,295.5,179.3,292,179.3z M292,108.7c-3.4,0-6.2-2.8-6.2-6.2
		c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C298.2,105.9,295.5,108.7,292,108.7z"/>
  </svg>
)

export default BakobaLogo